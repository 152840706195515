import axios from "axios"
import UtilAxiosDownload from "../util/utilAxiosDownload"
export class RelatoriosSaida {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Saida`
  }
  imprimir(dataRetornoPrevista, idPraca) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios({
      url: this.urlServico + `/GetSaidasPorPracaXlsx?dataRetornoPrevista=${dataRetornoPrevista}&idPraca=${idPraca}`,
      method: "GET",
      responseType: "blob",
      ...config // important
    })
  }
  imprimirVendas(dataInicioCobrança, dataFimCobrança, idPraca, idsProdutos) {
    let util = new UtilAxiosDownload()
    let config = util.config
    return axios
      .post(
        this.urlServico + "/GetVendasPorReferenciaPeriodoXlsx",
        { dataInicioCobrança, dataFimCobrança, idPraca, idsProdutos },
        config
      )
      .catch(error => {
        alert("Erro ao gerar relatório " + error)
        return Promise.reject(error)
      })
  }
  imprimirVendasPorLinha(dataInicioCobrança, dataFimCobrança) {
    let util = new UtilAxiosDownload()
    let config = util.config
    return axios
      .post(
        this.urlServico + "/GetVendasPorPeriodoPorLinhaXlsx",
        { dataInicioCobrança, dataFimCobrança },
        config
      )
      .catch(error => {
        alert("Erro ao gerar relatório " + error)
        return Promise.reject(error)
      })
  }
  imprimirCobrancaPorPraca(dataInicioCobrança, dataFimCobrança, idPraca) {
    let util = new UtilAxiosDownload()
    let config = util.config
    return axios
      .post(
        this.urlServico + "/GetCobrancaPorPracaXlsx",
        { dataInicioCobrança, dataFimCobrança, idPraca },
        config
      )
      .catch(error => {
        alert("Erro ao gerar relatório " + error)
        return Promise.reject(error)
      })
  }
}
